import React from "react";
import { OurProcess } from "../components/our-process";
import AboutOurProducts from "../components/partials/about-our-products";
import Product from "../components/product";
import GoogleReviews from "../components/sections/google-reviews";
import Guarantee from "../components/sections/guarantee";

const CoversPage = () => {
  return (
    <>
      <Product category="covers" />
      <OurProcess />
      <Guarantee />
      <AboutOurProducts />
      <GoogleReviews />
    </>
  );
};
export default CoversPage;
